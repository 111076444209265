// https://www.algolia.com/doc/guides/building-search-ui/resources/ui-and-ux-patterns/in-depth/autocomplete/tutorials/how-to-display-results-from-multiple-indices-with-autocomplete-js/

const client = algoliasearch("VVTW1CXEMZ", "0afc959d7153ab7d577a85e7aea08db6")

let index_suffix = window.location.host.includes('.references.wiki') ? "_production" : "_development"

indicies = {
  'entities': {
    title: 'knowledge',
    index: client.initIndex(`Entity${index_suffix}`)
  },
  'refs': {
    title: 'references',
    index: client.initIndex(`Ref${index_suffix}`)
  },
}

autocomplete(
  '#home-show #search-box',
  {
    templates: {
      dropdownMenu: `
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="aa-dataset-entities"></div>
          </div>
          <div class="col-12 col-md-6">
            <div class="aa-dataset-refs"></div>
          </div>
        </div>
      `
      // footer: '<div class="branding">Powered by <img src="https://www.algolia.com/static_assets/images/press/downloads/algolia-logo-light.svg" /></div>'
    },
  }, Array.from(Object.entries(indicies), ([name, value]) => {
    return {
      source: autocomplete.sources.hits(value.index, { hitsPerPage: 10 }),
      name: name,
      templates: {
        header: `
          <h5 class="suggestions-category m-2">${value.title}</h5>
        `,
        suggestion: function(suggestion, _answer) {
          poster_src = suggestion.poster_src || 'https://i.picsum.photos/id/135/240/320.jpg'

          switch (name) {
            case 'entities':
              // TODO handle entity without slug
              return `<a href="/knowledge/${suggestion.category}/${suggestion.slug}" class="media m-1">
                <img src="${poster_src}" class="poster mr-3">
                <div class="media-body">
                  <p class="mt-0">${suggestion._highlightResult.name.value}</p>
                </div>
              </a>`
              break;
            case 'refs':
              // TODO handle entity without slug
              return `<a href="/knowledge/${suggestion.host_category}/${suggestion.host_slug}" class="media m-1">
                <img src="${poster_src}" class="poster mr-3">
                <div class="media-body">
                  <p class="my-0">${suggestion._highlightResult.host_name.value}</p>
                  <p class="mt-0 text-decoration-none text-body text-muted">${suggestion._highlightResult.content.value}</p>
                </div>
              </a>`
              break;
          }
        }
      }
    }
  })
)//.on('autocomplete:shown', function(){ debugger })
